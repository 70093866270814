*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.tw-container {
  width: 100%;
}

@media (width >= 576px) {
  .tw-container {
    max-width: 576px;
  }
}

@media (width >= 768px) {
  .tw-container {
    max-width: 768px;
  }
}

@media (width >= 992px) {
  .tw-container {
    max-width: 992px;
  }
}

@media (width >= 1200px) {
  .tw-container {
    max-width: 1200px;
  }
}

@media (width >= 1400px) {
  .tw-container {
    max-width: 1400px;
  }
}

.tw-absolute {
  position: absolute;
}

.tw-relative {
  position: relative;
}

.tw-bottom-0 {
  bottom: 0;
}

.tw-bottom-5 {
  bottom: 1.25rem;
}

.tw-left-0 {
  left: 0;
}

.tw-right-0 {
  right: 0;
}

.tw-right-5 {
  right: 1.25rem;
}

.tw-z-10 {
  z-index: 10;
}

.tw-m-0 {
  margin: 0;
}

.tw-m-4 {
  margin: 1rem;
}

.tw-mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.tw-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.tw-my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.tw--mt-mdStageOffset {
  margin-top: -178px;
}

.tw-mt-4 {
  margin-top: 1rem;
}

.tw-flex {
  display: flex;
}

.tw-grid {
  display: grid;
}

.tw-h-12 {
  height: 3rem;
}

.tw-h-20 {
  height: 5rem;
}

.tw-h-24 {
  height: 6rem;
}

.tw-h-64 {
  height: 16rem;
}

.tw-h-screen {
  height: 100vh;
}

.tw-w-12 {
  width: 3rem;
}

.tw-w-auto {
  width: auto;
}

.tw-w-full {
  width: 100%;
}

.tw-flex-shrink-0 {
  flex-shrink: 0;
}

.tw-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.tw-grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.tw-flex-col {
  flex-direction: column;
}

.tw-items-start {
  align-items: flex-start;
}

.tw-items-center {
  align-items: center;
}

.tw-justify-center {
  justify-content: center;
}

.tw-gap-4 {
  gap: 1rem;
}

.tw-gap-5 {
  gap: 1.25rem;
}

.tw-gap-7 {
  gap: 1.75rem;
}

.tw-gap-x-5 {
  -moz-column-gap: 1.25rem;
  column-gap: 1.25rem;
}

.tw-gap-y-5 {
  row-gap: 1.25rem;
}

.tw-space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.tw-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.tw-space-y-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
}

.tw-self-start {
  align-self: flex-start;
}

.tw-overflow-hidden {
  overflow: hidden;
}

.tw-rounded-full {
  border-radius: 9999px;
}

.tw-border-4 {
  border-width: 4px;
}

.tw-border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.tw-bg-DigitalConsulting {
  --tw-bg-opacity: 1;
  background-color: rgb(251 229 78 / var(--tw-bg-opacity));
}

.tw-bg-ProcessOptimization {
  --tw-bg-opacity: 1;
  background-color: rgb(243 140 150 / var(--tw-bg-opacity));
}

.tw-bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.tw-p-10 {
  padding: 2.5rem;
}

.tw-p-5 {
  padding: 1.25rem;
}

.tw-px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.tw-py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.tw-py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.tw-py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.tw-text-left {
  text-align: left;
}

.tw-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.tw-text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.tw-text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.tw-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.tw-text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.tw-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.tw-text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.tw-font-extrabold {
  font-weight: 800;
}

.tw-font-extralight {
  font-weight: 200;
}

.tw-font-semibold {
  font-weight: 600;
}

.tw-uppercase {
  text-transform: uppercase;
}

.tw-leading-normal {
  line-height: 1.5;
}

.tw-tracking-tighter {
  letter-spacing: -.05em;
}

.tw-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.tw-shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (width >= 576px) {
  .sm\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (width >= 768px) {
  .md\:tw-h-36 {
    height: 9rem;
  }

  .md\:tw-w-2\/3 {
    width: 66.6667%;
  }

  .md\:tw-max-w-screen-md {
    max-width: 768px;
  }

  .md\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:tw-flex-row {
    flex-direction: row;
  }

  .md\:tw-items-center {
    align-items: center;
  }

  .md\:tw-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tw-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:tw-text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:tw-text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:tw-text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }
}

@media (width >= 992px) {
  .lg\:tw-bottom-0 {
    bottom: 0;
  }

  .lg\:tw-left-0 {
    left: 0;
  }

  .lg\:-tw-m-0 {
    margin: 0;
  }

  .lg\:tw-h-2\/3 {
    height: 66.6667%;
  }

  .lg\:tw-w-auto {
    width: auto;
  }

  .lg\:tw-max-w-none {
    max-width: none;
  }

  .lg\:tw-max-w-screen-lg {
    max-width: 992px;
  }

  .lg\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:tw-flex-col {
    flex-direction: column;
  }

  .lg\:tw-items-center {
    align-items: center;
  }

  .lg\:tw-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .lg\:tw-text-center {
    text-align: center;
  }

  .lg\:tw-text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:tw-text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

@media (width >= 1200px) {
  .xl\:tw-max-w-screen-xl {
    max-width: 1200px;
  }
}
/*# sourceMappingURL=index.3f710fe0.css.map */
